<template>
  <modal
    :adaptive="true"
    :click-to-close="false"
    name="no-params-message-modal"
    :height="'auto'"
    :max-width="378"
    :resizable="true"
    class="app-modal error-modal"
  >
    <div class="modal-header">{{ company.name }} Dashboard!</div>
    <div v-if="company" class="modal-content font-thin">
      <p>
        Dit dashboard is toegankelijk via de mail die u van
        {{ company.name }} ontvangt. <br />Indien u geen maandelijkse mail
        ontvangt neem dan contact op met {{ company.name }}.
      </p>
      <p>
        Telefoon: {{ company.phone }}
        <br />
        <a :href="companyMailTo">{{ company.email }}</a>
      </p>
    </div>
  </modal>
</template>
<script>
import Session from '@/Session';

export default {
  data() {
    return {
      company: Session.companyConfig.company,
      companyMailTo: 'mailto:' + this.company ?? this.company.email,
    };
  },
};
</script>
