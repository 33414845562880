import Vue from 'vue';
import VueRouter from 'vue-router';
import Dashboard from '@/views/Dashboard';
import SignIn from '@/views/SignIn';
import SelectMeter from '@/views/SelectMeter';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    props: true,
  },
  {
    path: '/sign-in',
    name: 'SignIn',
    component: SignIn,
    props: true,
  },
  {
    path: '/select',
    name: 'SelectMeter',
    component: SelectMeter,
    props: true,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
