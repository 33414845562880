const companyConfig = {
  company: {
    name: 'Sundata',
    email: 'support@sundata.nl',
    phone: '030 207 2786',
    footerLogo: 'logo-dark',
  },
  theme: {
    '--background-color': '#fff',
    '--main-color': '#292929',
    '--inverse-main-color': '#fff',
    '--secondary-color': '#ffab05',
    '--accent-color': '#ffcd8c',
    '--light-secondary-color': '#a7a9ae',
    '--dark-secondary-color': '#2a2e3f',
    '--darken-secondary-color': '#2f3548',
    '--error-color': '#a82e25',
  },
};

export default companyConfig;
