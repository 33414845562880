const monthsNames = {
  short: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'Mei',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Dec',
  ],
  full: [
    'Januari',
    'Februari',
    'Maart',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Augustus',
    'September',
    'Oktober',
    'November',
    'December',
  ],
};

const monthScoreImpact = [
  0.18, // Jan
  0.27, // Feb
  0.58, // Mar
  0.88, // Apr
  0.96, // May
  1.0, // Jun
  0.98, // Jul
  0.85, // Aug
  0.65, // Sep
  0.42, // Oct
  0.2, // Nov
  0.14, // Dec
];

export default class MonthCard {
  id;
  startDate;
  endDate;
  isCurrentMonth;
  currentMonthExpectedValue;
  monthNumber;
  month;
  fullMonthName;
  year;
  actual_yield;
  expected_yield;
  co;
  price;
  monthWeather;
  weatherAvg;
  score;

  constructor(
    id,
    amountInCentsPerKwh,
    promisedTotalInKwh,
    yieldData,
    irradianceData
  ) {
    this.id = id;
    this.startDate = yieldData.start_date;
    this.endDate = yieldData.end_date;
    this.currentMonthExpectedValue = promisedTotalInKwh;
    this.actual_yield = parseInt(yieldData.actual_yield_in_kwh);
    this.expected_yield = parseInt(yieldData.calculated_yield_in_kwh);
    this.co = parseInt(yieldData.actual_yield_in_kwh * 0.283);
    this.price = parseInt(
      (yieldData.actual_yield_in_kwh * amountInCentsPerKwh) / 100
    );

    const date = new Date(yieldData.start_date);
    const todayDate = new Date();

    this.monthNumber = date.getMonth();
    this.isCurrentMonth =
      date.getMonth() === todayDate.getMonth() &&
      date.getFullYear() === todayDate.getFullYear();
    this.month = monthsNames.short[date.getMonth()];
    this.fullMonthName = monthsNames.full[date.getMonth()];
    this.year = date.getFullYear();

    this.weatherAvg = this.getAvgWeatherStatsPerMonth(
      date.getMonth() + 1,
      date.getFullYear(),
      irradianceData
    );

    const monthData = irradianceData.filter((item) => {
      const itemDate = new Date(item.date);
      return (
        itemDate.getMonth() === date.getMonth() &&
        itemDate.getFullYear() === date.getFullYear()
      );
    });

    this.monthWeather =
      monthData.length > 0 ? monthData[0].irradiance_in_jcm2 : 0;

    const yieldRatio = yieldData.actual_yield_in_kwh
      ? yieldData.actual_yield_in_kwh / yieldData.calculated_yield_in_kwh
      : 0;

    const monthImpact = monthScoreImpact[date.getMonth()];

    const yieldScore = 7 + (yieldRatio - 1) * 10 * monthImpact;
    // Cap the yield score on a minimum of 1 and maximum of 10. NaN if yieldScore is NaN
    const score = Math.min(Math.max(yieldScore, 1), 10);

    this.score = !score || isNaN(score) ? '?' : Math.round(score);
  }

  getAvgWeatherStatsPerMonth(monthIndex, currentYear, irradianceData) {
    const historicalMonthData = irradianceData.filter((item) => {
      const date = new Date(item.date);
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return year < currentYear && month === monthIndex;
    });

    const totalIrradiance = historicalMonthData.reduce(
      (acc, current) => acc + current.irradiance_in_jcm2,
      0
    );

    return Math.round(totalIrradiance / historicalMonthData.length);
  }
}
