<template>
  <div class="value-toggle">
    <div
      v-for="item in options"
      @click="$emit('change', item.id)"
      :key="item.id"
      :class="{ active: value === item.id }"
      class="value-toggle-item"
    >
      {{ item.value }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss">
@import '../assets/scss/variables.scss';
.value-toggle {
  display: flex;
  padding: 2px 5px 3px;
  background: var(--secondary-color);
  border-radius: 10px;
  align-items: center;
  &-item {
    padding: 10px 10px 8px;
    margin-right: 6px;
    font-size: 14px;
    @media screen and (max-width: 700px) {
      font-size: 10px;
    }
    cursor: pointer;
    &:last-child {
      margin-right: 0px;
    }
    &.active {
      border-radius: 10px;
      background: var(--accent-color);
    }
  }
}
</style>
