export default {
  methods: {
    // Gives a number [0;1] that indicates which pct
    // of solar energy is available between the
    // start of the year, and the give dayOfYear
    getExpectationFactor(dayOfYear) {
      return dayOfYear <= 172
        ? (dayOfYear * dayOfYear) / 55000
        : 1 - ((dayOfYear - 366) * (dayOfYear - 366)) / 73000;
    },
  },
};
