<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  mounted() {
    document.title = this.getCapitalizedCompanyName();
    document.getElementById('favicon').href = this.getFavIcon();
  },
};
</script>
