<template>
  <div class="layout">
    <loading :active.sync="isLoading" :is-full-page="true"></loading>
    <div class="header">
      <div class="logo-wrapper">
        <img class="logo" :src="getCompanyLogo()" />
      </div>
    </div>
    <div class="content">
      <div class="flex align-center items-center">
        <div class="content-description" style="margin-bottom: 39px">
          <h1>Gefeliciteerd met uw zonnepanelen.</h1>
          <p class="font-thin">
            De opbrengst van zonnepanelen verschilt door het jaar heen. In de
            maanden mei, juni en juli geven de zonnepanelen de hoogste
            opbrengst.
          </p>
        </div>
        <div class="sign-in-container">
          <h1>Selecteer een meter</h1>
          <button
            v-for="meter in meters"
            v-bind:key="meter.reference_identifier"
            v-on:click="getMeterData(meter.reference_identifier)"
            class="btn"
            style="margin-bottom: 20px"
          >
            {{ meter.name }} - {{ meter.peak_in_watt }} Watt
          </button>
        </div>
      </div>
    </div>
    <Footer :logo-url="options.smallLogoUrl" />
  </div>
</template>

<script>
import Footer from '@/components/Footer';
import SiriusApi from '@/data/SiriusApi';
import Loading from 'vue-loading-overlay';
import Session from '@/Session';

export default {
  name: 'SelectMeter',
  components: {
    Footer,
    Loading,
  },
  props: {
    refIds: Array,
  },
  data() {
    return {
      error: null,
      isLoading: false,
      postalCode: null,
      meters: null,
      options: {
        logoUrl: '/img/logo.png',
        smallLogoUrl: '/img/logo.png',
      },
    };
  },
  mounted() {
    this.postalCode = Session.postalCode;
    Session.isMultipleMeters = true;

    if (!this.refIds) {
      const plantCode = Session.plantCode;

      new SiriusApi()
        .getPlantMeterRefIds(this.postalCode, plantCode)
        .then((response) => {
          this.meters = response.data;
        });
    } else {
      this.meters = this.refIds;
    }
  },
  methods: {
    getMeterData(refId) {
      this.isLoading = true;

      const api = new SiriusApi();
      api
        .getMeterPlant(this.postalCode, refId)
        .then((response) => {
          let meterData = response.data;

          api
            .getWeather(meterData.plant.weather_station_id)
            .then((response) => {
              let res = response.data;
              this.setSessionAndGoToDashboard(refId, meterData, res.data);
            })
            .catch(() => {
              this.error = 'Het weer kon niet worden opgehaald';
              this.isLoading = false;
            });
        })
        .catch(() => {
          this.error = 'Gegevens niet gevonden';
          this.isLoading = false;
        });
    },
    setSessionAndGoToDashboard(refId, meterData, weatherData) {
      this.selecting = false;
      this.isLoading = false;

      Session.ref = refId;

      this.$router.push({
        name: 'Dashboard',
        params: {
          meterData: meterData,
          weatherData: weatherData,
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import './src/assets/scss/app.scss';
@import './src/assets/scss/variables.scss';

.sign-in-container {
  display: flex;
  flex-direction: column;
  height: 400px;
  width: 400px;
}

.error-message {
  color: var(--error-color);
}
</style>
