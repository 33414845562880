const companyConfig = {
  company: {
    name: 'Saman',
    email: 'klantadvies@samangroep.nl',
    phone: '+3185 620 3100',
    footerLogo: 'logo-light',
  },
  theme: {
    '--background-color': '#262626',
    '--main-color': '#fff',
    '--inverse-main-color': '#000',
    '--secondary-color': '#3c3c3c',
    '--accent-color': '#ffc113',
    '--light-secondary-color': '#a7a9ae',
    '--dark-secondary-color': '#2a2e3f',
    '--darken-secondary-color': '#2f3548',
    '--error-color': '#a82e25',
  },
};

export default companyConfig;
