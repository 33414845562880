import Vue from 'vue';
import VueGtag from 'vue-gtag';

import VModal from 'vue-js-modal';
import vSelect from 'vue-select';
import App from './App';

Vue.use(VueGtag, {
  config: { id: 'UA-137003463-2' },
});
Vue.use(VModal);

const AnalyticsWrap = {};
AnalyticsWrap.install = function () {
  Vue.prototype.$trackAnalytics = function (action, data) {
    if (process.env.NODE_ENV === 'production') {
      Vue.$gtag.event(action, data);
    }
  };
};

Vue.use(AnalyticsWrap);

Vue.component('v-select', vSelect);

import VueAwesomeSwiper from 'vue-awesome-swiper';

// import style
import 'swiper/css/swiper.css';

// Set company configuration
import CompanyConfiguration from './mixins/CompanyConfiguration';
Vue.mixin(CompanyConfiguration);
CompanyConfiguration.methods.load();

import router from './router';

Vue.use(VueAwesomeSwiper /* { default options with global component } */);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
