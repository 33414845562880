<template>
  <div class="layout">
    <loading :active.sync="isLoading" :is-full-page="true"></loading>
    <div class="header">
      <div class="logo-wrapper">
        <img class="logo" :src="getCompanyLogo()" alt="logo" />
      </div>
    </div>
    <div class="content">
      <div class="flex align-center items-center">
        <div class="content-description" style="margin-bottom: 39px">
          <h1>Gefeliciteerd met uw zonnepanelen.</h1>
          <p class="font-thin">
            De opbrengst van zonnepanelen verschilt door het jaar heen. In de
            maanden mei, juni en juli geven de zonnepanelen de hoogste
            opbrengst.
          </p>
        </div>
        <div>
          <h1>Log in</h1>
          <form @submit.prevent="submit" class="sign-in-container">
            <input
              v-model="postalCode"
              ref="postalCode"
              class="input"
              type="text"
              placeholder="Vul hier de postcode in (bijv. 1234AB)"
            />
            <div>
              <input
                v-model="code"
                ref="code"
                class="input"
                type="text"
                placeholder="Vul hier de unieke code in"
              />
              <a
                id="info-circle"
                href="http://support.sundata.nl/nl/articles/6096772-unieke-code"
                target="_blank"
              >
                <i class="icon-icon_info" />
              </a>
            </div>
            <button class="btn" type="submit">Log in</button>
            <p class="error-message" v-if="error">{{ error }}</p>
          </form>
        </div>
      </div>
    </div>
    <Footer :logo-url="options.smallLogoUrl" />
  </div>
</template>

<script>
import Footer from '@/components/Footer';
import SiriusApi from '@/data/SiriusApi';
import Loading from 'vue-loading-overlay';
import Session from '@/Session';

export default {
  name: 'SignIn',
  components: {
    Footer,
    Loading,
  },
  props: {
    givenError: String,
  },
  data() {
    return {
      postalCode: null,
      code: null,
      error: null,
      isLoading: false,
      options: {
        logoUrl: '/img/logo.png',
        smallLogoUrl: '/img/logo.png',
      },
    };
  },
  mounted() {
    this.$refs.postalCode.focus();

    if (this.givenError) {
      this.error = this.givenError;
    }
  },
  methods: {
    submit() {
      this.error = null;
      this.isLoading = true;

      this.postalCode = this.postalCode ? this.postalCode.trim() : null;
      this.code = this.code ? this.code.trim() : null;

      if (!this.postalCode || !this.code) {
        this.error = 'Voer alstublieft alle velden in';
        this.isLoading = false;
        return;
      }

      if (!/^P.{6}$/.test(this.code)) {
        this.getMeterData(this.code);
        return;
      }

      new SiriusApi()
        .getPlantMeterRefIds(this.postalCode, this.code)
        .then((response) => {
          let res = response.data;

          Session.plantCode = this.code;

          if (res && res.length === 0) {
            this.error = 'Geen meter gevonden op dit adres';
            this.isLoading = false;
          } else if (res && res.length === 1) {
            this.getMeterData(res[0].reference_identifier);
          } else {
            this.isLoading = false;
            Session.postalCode = this.postalCode;
            this.$router.push({
              name: 'SelectMeter',
              params: { refIds: res },
            });
          }
        });
    },
    getMeterData(refId) {
      this.isLoading = true;

      const api = new SiriusApi();
      api
        .getMeterPlant(this.postalCode, refId)
        .then((response) => {
          const meterdata = response.data;

          api
            .getWeather(meterdata.plant.weather_station_id)
            .then((response) => {
              let res = response.data;
              if (!res.success) {
                this.error = 'Het weer kon niet worden opgehaald';
                this.isLoading = false;
              }
              this.setSessionAndGoToDashboard(refId, meterdata, res);
            })
            .catch(() => {
              this.error = 'Het weer kon niet worden opgehaald';
              this.isLoading = false;
            });
        })
        .catch(() => {
          this.error = 'Gegevens niet gevonden';
          this.isLoading = false;
        });
    },
    setSessionAndGoToDashboard(refId, meterData, weatherData) {
      this.isLoading = false;

      Session.ref = refId;
      Session.postalCode = this.postalCode;

      this.$router.push({
        name: 'Dashboard',
        params: {
          meterData: meterData,
          weatherData: weatherData,
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import './src/assets/scss/app.scss';
@import './src/assets/scss/variables.scss';

.sign-in-container {
  display: flex;
  flex-direction: column;
  height: 400px;
  width: 500px;

  input {
    width: 90%;
  }

  button {
    width: 90%;
  }
}

#info-circle {
  all: unset;
  cursor: pointer;
  vertical-align: middle;
  font-size: 25px;
  margin-left: 5px;
  color: #fff;

  &:hover {
    color: var(--accent-color);
  }
}

.error-message {
  color: var(--error-color);
}
</style>
