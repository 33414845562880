<template>
  <div v-if="item" class="card">
    <div class="card-header">
      {{ item.month }}
    </div>
    <div class="card-body">
      <div class="card-body-top">
        <div class="font-bold title">OPGEWEKT</div>
        <div class="subtitle font-bold">
          {{ this.item.actual_yield }}
          <span class="font-normal">kwh</span>
        </div>
      </div>
      <div class="card-body-mid">
        <div class="flex">
          <div class="value-item">
            <div class="value font-bold">
              {{ item.co }}
              <span>
                <i class="icon-KG" style="font-size: 12px"></i>
              </span>
            </div>
            <div class="subtitle">BESPAARDE CO2 DEZE MAAND</div>
          </div>
          <div class="value-item">
            <div class="value font-bold"><span>€</span> {{ item.price }}</div>
            <div class="subtitle">BESPAARD IN € DEZE MAAND</div>
          </div>
        </div>
      </div>
      <div class="card-body-bottom">
        <div class="subtitle" @click="showInfoModal">
          Systeem prestatie
          <i class="icon-icon_info card-info"></i>
        </div>
        <div class="value font-bold" @click="showInfoModal">
          {{ item.score }}
        </div>
        <div
          @click="showWeatherModal"
          class="card-body-bottom-images items-center justify-space"
          style="text-align: center"
        >
          <i
            style="font-size: 25px"
            :class="getClass()"
            class="icon-icon_sun"
          ></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ExpectationHelper from '@/mixins/ExpectationHelper';
import Session from '@/Session';

export default {
  mixins: [ExpectationHelper],
  props: {
    item: {
      type: Object,
      default: null,
    },
    analytics: {
      type: Object,
      default: () => ({}),
    },
    operationalSince: {
      type: String,
    },
  },
  data() {
    return {
      company: Session.companyConfig.company,
    };
  },
  methods: {
    showWeatherModal() {
      this.$emit('show-weather-modal', this.item);
      this.$trackAnalytics('weather_info_box_open', this.analytics);
    },
    showInfoModal() {
      let weatherScore =
        (this.item.monthWeather / this.item.weatherAvg - 1) * 10 + 7;
      weatherScore =
        weatherScore < 6 ? 6 : weatherScore > 10 ? 10 : weatherScore; // always should be min 6 and max 10

      let body = `Het weer voor zonne-energie was deze maand ${this.getRankDependsOnScore(
        Math.round(weatherScore)
      )}.
        De verwachte opbrengst op basis van het weer is ${this.getExpectedYield(
          this.item.expected_yield
        )}. <br>
        De daadwerkelijke opbrengst is ${this.item.actual_yield} kWh.
        Hiermee rekening houdend is de systeem prestatie <strong>${this.getRankDependsOnScore(
          this.item.score
        )}.</strong>`;

      if (this.item.score < 6) {
        body = body.concat(
          `<br> <br> Indien u vorige maand ook een onvoldoende scoorde is onze aanbeveling om
            contact op te nemen met ${this.company.name} klantenservice via
            <a :href="mailto:${this.company.email}">${this.company.email}</a> of
            <a :href="tel:${this.company.phone}">${this.company.phone}</a>`
        );
      }

      this.$emit('show-info-modal', {
        title: `Systeem prestatie in ${this.item.fullMonthName.toLowerCase()}.`,
        body: body,
      });

      this.$trackAnalytics('system_score_box_open', this.analytics);
    },
    getRankDependsOnScore(value) {
      const values = {
        10: 'extreem goed',
        9: 'extreem goed',
        8: 'zeer goed',
        7: 'goed',
        6: 'matig',
        '?': 'nog niet bekend',
      };

      return values[value] || 'slecht';
    },
    getClass(min = 85, max = 115) {
      if (!this.item.monthWeather) return 'disabled';

      const curMonthValue = parseFloat(this.item.monthWeather);
      const avgMonthValue = this.item.weatherAvg;
      const percentage = (curMonthValue / avgMonthValue) * 100;

      if (percentage <= max && percentage >= min) {
        return 'disabled';
      } else if (percentage < min) {
        return 'nonactive';
      } else if (percentage > max) {
        return 'active';
      } else {
        return 'disabled';
      }
    },
    getExpectedYield(expected_yield) {
      if (expected_yield === 0 || isNaN(expected_yield)) {
        return `<strong>nog niet berekend</strong>`;
      }
      return `${parseInt(expected_yield)} kWh`;
    },
  },
};
</script>

<style lang="scss" />
