<template>
  <div class="header">
    <div class="logo-wrapper">
      <img v-if="logoUrl" class="logo" :src="logoUrl" />
    </div>
    <div class="flex header-content">
      <div class="flex align-start">
        <div class="flex align-center">
          <div class="header-stat">
            <div class="header-stat-title">
              Jaaropbrengst / <span class="font-thin">Jaardoel</span>
            </div>
            <div>
              {{ parseInt(actualKwh * selectedValue.valueMultiply) }}
              <span class="font-thin"
                >/{{ parseInt(yearGoal * selectedValue.valueMultiply) }}
                {{ selectedValue.value }}</span
              >
            </div>
          </div>
          <div class="header-stat">
            <div class="header-stat-title">TOTAAL OPBRENGST</div>
            <div>
              {{ parseInt(totalKwhAllTimes * selectedValue.valueMultiply)
              }}<span class="font-thin"> {{ selectedValue.value }}</span>
              <i
                @click="showInfoModal"
                style="margin-left: 4px; font-size: 18px"
                class="icon-icon_info"
              ></i>
            </div>
          </div>
        </div>
        <ValueToggle
          @change="changeToggle($event)"
          :value="selectedValue.id"
          :options="toggleValues"
          class="header-value-toggle"
        />
      </div>

      <div class="flex">
        <button
          v-if="hasMultipleMeters"
          v-on:click="changeMeter"
          class="btn btn-margin"
        >
          {{ meterName }}
        </button>
        <button v-on:click="logOut" class="btn btn-margin">Uitloggen</button>
      </div>
    </div>
  </div>
</template>

<script>
import ValueToggle from './ValueToggle';
import ExpectationHelper from '@/mixins/ExpectationHelper';
import moment from 'moment';
import Session from '@/Session';

export default {
  props: {
    logoUrl: {
      type: String,
      default: null,
    },
    actualKwh: {
      type: Number,
      default: 0,
    },
    amountInCentsPerKwh: {
      type: Number,
      default: 0,
    },
    totalKwhAllTimes: {
      type: Number,
      default: 0,
    },
    totalKwhYear: {
      type: Number,
      default: 0,
    },
    operationalSince: {
      type: String,
    },
    selectedYear: {
      type: Number,
    },
    analytics: {
      type: Object,
      default: () => ({}),
    },
    meterName: String,
  },
  components: {
    ValueToggle,
  },
  mixins: [ExpectationHelper],
  data() {
    return {
      hasMultipleMeters: null,
      selectedValue: {},
    };
  },
  mounted() {
    this.hasMultipleMeters = Session.isMultipleMeters;
    this.selectedValue = this.toggleValues[1];
  },

  computed: {
    yearGoal() {
      const operationalMoment = moment(this.operationalSince);

      if (this.selectedYear > operationalMoment.year()) {
        return this.totalKwhYear;
      } else {
        const dayOfYear = operationalMoment.format('DDD');
        const expectationFactor = this.getExpectationFactor(dayOfYear);
        return (1 - expectationFactor) * this.totalKwhYear;
      }
    },
    toggleValues() {
      return [
        {
          id: 1,
          value: '€',
          valueMultiply: this.amountInCentsPerKwh / 100,
        },
        {
          id: 2,
          value: 'KWH',
          valueMultiply: 1,
        },
      ];
    },
  },
  methods: {
    showInfoModal() {
      this.$emit('show-info-modal', {
        title: null,
        body: `
            <strong>Jaardoel</strong> <br>
            Het jaardoel is berekend voor de installatie van de zonnepanelen. <br><br>
            <strong>Totaal Opbrengst</strong> <br>
            De totale opbrengst sinds de zonnepanelen verbonden zijn..
        `,
      });
      this.$trackAnalytics('totals_info_box_open', this.analytics);
    },
    changeToggle(id) {
      this.selectedValue = this.toggleValues.find((v) => v.id === id);
    },
    changeMeter() {
      this.$router.push('select');
    },
    logOut() {
      Session.flush();
      this.$router.push('/sign-in');
    },
  },
};
</script>

<style lang="scss">
.header {
  display: flex;
  margin-bottom: 60px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: flex-start;
  width: 100%;

  @media only screen and (max-width: 700px) {
    margin-bottom: 0;
  }

  &-content {
    @media only screen and (max-width: 700px) {
      padding: 15px 0;
      border-top: 1px solid #363636;
      border-bottom: 1px solid #363636;
      width: 100%;
      justify-content: end;
      flex-wrap: wrap;
    }
  }

  @media only screen and (max-width: 700px) {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &-value-toggle {
    margin-left: 43px;
    @media only screen and (max-width: 700px) {
      margin-left: 25px;
    }
  }

  &-stats {
    display: flex;
  }

  &-stat {
    text-transform: lowercase;
    font-size: 20px;
    margin-left: 53px;

    &:first-child {
      margin-left: 0;
    }

    @media only screen and (max-width: 700px) {
      font-size: 14px;
      margin-left: 25px;
    }
  }

  &-stat-title {
    font-size: 8px;
    letter-spacing: 1px;
    line-height: 8px;
    font-weight: 700;
    margin-bottom: 9px;
    text-transform: uppercase;
  }
}
.btn-margin {
  margin-left: 10px;

  @media only screen and (max-width: 700px) {
    margin-top: 15px;
  }
}

.logo-wrapper {
  height: 50px;
}

.logo {
  max-width: 204px;
  max-height: 50px;
  height: 100%;
  width: auto;
  display: block;
  @media only screen and (max-width: 700px) {
    margin-bottom: 5px;
  }
}
</style>
