const companyConfig = {
  company: {
    name: 'Funky',
    email: 'support@sundata.nl',
    phone: '030 207 2786',
    footerLogo: 'logo-dark',
  },
  theme: {
    '--background-color': '#fafa6e',
    '--main-color': '#00898a',
    '--inverse-main-color': '#e5794b',
    '--secondary-color': '#92dc7e',
    '--accent-color': '#39b48e',
    '--error-color': '#a82e25',
    '--light-secondary-color': '#fcbc4f',
    '--dark-secondary-color': '#39b48e',
    '--darken-secondary-color': '#c4ec74',
  },
};

export default companyConfig;
