<template>
  <modal
    :adaptive="true"
    :click-to-close="false"
    name="entry-error-modal"
    :height="'auto'"
    :max-width="378"
    :resizable="true"
    class="app-modal error-modal"
  >
    <div class="modal-header">Oops</div>
    <div v-if="company" class="modal-content font-thin">
      <p>
        Er is iets mis gegaan met het laden van jouw dashboard. Neem dan contact
        op met {{ company.email }} om de juiste link te ontvangen.
      </p>
      <p>
        Telefoon: {{ company.phone }}
        <br />
        <a :href="companyMailTo">{{ company.email }}</a>
      </p>
    </div>
  </modal>
</template>
<script>
import Session from '@/Session';

export default {
  data() {
    return {
      company: Session.companyConfig.company,
      companyMailTo: 'mailto:' + this.company ?? this.company.email,
    };
  },
};
</script>
