<template>
  <div v-if="item" class="card inversed-card">
    <div class="card-header">
      {{ item.month }}
    </div>
    <div class="card-body">
      <div class="card-body-top">
        <div class="font-bold title">
          OPGEWEKT T/M {{ getFormattedEndDate().toUpperCase() }}
        </div>
        <div class="subtitle font-bold">
          <div v-if="isFirstDayOfTheMonth()" style="font-size: 14px">
            Morgen beschikbaar
          </div>
          <div v-else>
            {{ this.item.actual_yield }}
            <span class="font-normal">kwh</span>
          </div>
        </div>
      </div>

      <div class="card-body-mid">
        <div class="subtitle font-bold">VERWACHT DEZE MAAND</div>
        <div class="title font-bold">
          {{ getExpectationForMonth() }}
          <span class="font-normal">kwh</span>
        </div>
      </div>
      <div class="card-expected-placeholder">
        <div>
          <span class="subtitle"> resultaten <br /> </span>
          <span class="title" v-html="remainingDaysHrString" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import ExpectationHelper from '@/mixins/ExpectationHelper';

export default {
  mixins: [ExpectationHelper],
  props: {
    item: {
      type: Object,
      default: null,
    },
    operationalSince: {
      type: String,
    },
  },
  computed: {
    remainingDaysHrString() {
      if (this.remainingDaysCount > 0) {
        return `beschikbaar over <br /> ${this.remainingDaysCount} dagen`;
      }
      return `morgen beschikbaar`;
    },
    remainingDaysCount() {
      const todayDate = new Date();
      const todayTime = new Date(todayDate.getTime());
      todayTime.setMonth(todayDate.getMonth() + 1);
      todayTime.setDate(0);
      return todayTime.getDate() > todayDate.getDate()
        ? todayTime.getDate() - todayDate.getDate()
        : 0;
    },
  },
  methods: {
    isFirstDayOfTheMonth() {
      return moment(this.item.endDate).isSame(moment().startOf('month'));
    },
    getFormattedEndDate() {
      return moment(this.item.endDate)
        .subtract(1, 'day')
        .locale('NL')
        .format('DD MMM');
    },
    getExpectationForMonth() {
      const operationalDate = moment(this.operationalSince);
      const cardStart = moment(this.item.startDate);
      const expectationStart = moment.max(operationalDate, cardStart);
      const expectationStartFactor = this.getExpectationFactor(
        expectationStart.clone().format('DDD')
      );
      const expectationEndFactor = this.getExpectationFactor(
        cardStart.endOf('month').format('DDD')
      );
      const expectedYieldForMonth =
        this.item.currentMonthExpectedValue *
        (expectationEndFactor - expectationStartFactor);
      return expectedYieldForMonth.toFixed(0);
    },
  },
};
</script>

<style lang="scss"></style>
