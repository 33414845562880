const companyConfig = {
  company: {
    name: 'Sunforce',
    email: 'support@sundata.nl',
    phone: '030 207 2786',
    footerLogo: 'logo-dark',
  },
  theme: {
    '--background-color': '#FFFFFF',
    '--main-color': '#106900',
    '--inverse-main-color': '#f8fbf1',
    '--secondary-color': '#E1DE2E',
    '--accent-color': '#fff49b',
    '--error-color': '#a82e25',
    '--light-secondary-color': '#363636',
    '--dark-secondary-color': '#73AA17',
    '--darken-secondary-color': '#54595F',
  },
};

export default companyConfig;
